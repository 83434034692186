import { useState } from 'react';
import headshot from '../assets/headshot.jpg';

export default function AboutMe() {
  const [hoveredSkill, setHoveredSkill] = useState(null);
  
  const skills = [
    { name: 'Web Development', level: 50 },
    { name: 'Flutter/Dart', level: 85 },
    { name: 'Game Development', level: 90 },
    { name: 'Cloud Solutions', level: 65 },
    { name: 'C++', level: 75 },
    { name: 'Java', level: 65 },
    { name: 'C#', level: 65 },
    { name: 'Networking', level: 50 },
  ];

  return (
    <section className="py-16 px-4 max-w-6xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-800 mb-8 text-center">About Me</h2>
      <div className="grid md:grid-cols-2 gap-12 items-center">
        {/* Profile Image Side */}
        <div className="flex flex-col items-center md:items-end">
          <div className="relative">
            <div className="w-64 h-64 rounded-full overflow-hidden border-4 border-teal-500/20">
              <img
                src={headshot}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-blue-100 rounded-full -z-10"></div>
            <div className="absolute -top-4 -left-4 w-24 h-24 bg-teal-100 rounded-full -z-10"></div>
          </div>
        </div>

        {/* Content Side */}
        <div className="space-y-8">
          <div className="space-y-4">
            <p className="text-gray-600 leading-relaxed">
              I'm passionate about creating elegant solutions to complex problems through code. 
              With a keen eye for detail and a love for clean architecture, I specialize in 
              building responsive and intuitive applications that deliver exceptional user experiences.
            </p>
            <p className="text-gray-600 leading-relaxed">
              When I'm not coding, you can find me exploring new technologies, contributing to 
              open-source projects, and staying up-to-date with the latest industry trends.
            </p>
          </div>

          {/* Skills */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-800">Technical Expertise</h3>
            <div className="grid grid-cols-2 gap-4">
              {skills.map((skill) => (
                <div
                  key={skill.name}
                  className="relative group"
                  onMouseEnter={() => setHoveredSkill(skill.name)}
                  onMouseLeave={() => setHoveredSkill(null)}
                >
                  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-gradient-to-r from-blue-500 to-teal-500 transition-all duration-500"
                      style={{
                        width: hoveredSkill === skill.name ? `${skill.level}%` : '0%'
                      }}
                    ></div>
                  </div>
                  <span className="text-sm text-gray-600 mt-1 block font-medium">{skill.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
