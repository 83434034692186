import { Link } from "react-router-dom";
import React from "react";

function ProjectCard({ title, img, description, links }) {
    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <img src={img} alt={title} className="w-full h-48 object-cover" />
            <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{title}</h3>
                <p className="text-gray-600 mb-4">{description}</p>

                <div className="flex space-x-2">
                    {links.map((link, index) => (
                        <Link
                            key={index}
                            to={link.url}
                            className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                        >
                            {link.text}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
