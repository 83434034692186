import React, { useState } from 'react';
import { signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { auth, storage } from '../firebase';

function DeleteAccountForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    async function deleteAll(storageRef) {
        // Delete all files in the folder
        const listResult = await listAll(storageRef);
        listResult.items.forEach(async (itemRef) => {
            await deleteObject(itemRef);
        });

        // Delete all subfolders in the folder
        listResult.prefixes.forEach(async (prefixRef) => {
            await deleteAll(prefixRef);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(false);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            //delete the users cloud storage folder
            const storageRef = ref(storage, `${user.uid}`);
            await deleteAll(storageRef);

            // If authentication is successful, delete the user
            await deleteUser(user);
            setSuccess(true);
            console.log('Account successfully deleted');
        } catch (error) {
            setError(error.message);
            console.error('Error deleting account:', error);
        }
    };

    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
          <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Account Deletion Request
          </h1>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete Account
              </button>
            </div>
          </form>
          {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}
          {success && (
            <p className="mt-2 text-center text-sm text-green-600">
              Account successfully deleted
            </p>
          )}
        </div>
      </div>
    );
};

export default DeleteAccountForm;
