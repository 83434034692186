import React from "react";
import { FaTwitter, FaLinkedin, FaGithub, FaDiscord } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <p>&copy; {new Date().getFullYear()} Luke Elrod. All rights reserved.</p>
        <div className="flex space-x-4">
          <a href="https://x.com/LukeElrod_" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="text-2xl hover:text-blue-400 transition-colors duration-300" />
          </a>
          <a href="https://www.linkedin.com/in/luke-elrod-career/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="text-2xl hover:text-blue-600 transition-colors duration-300" />
          </a>
          <a href="https://github.com/LukeElrod" target="_blank" rel="noopener noreferrer">
            <FaGithub className="text-2xl hover:text-gray-400 transition-colors duration-300" />
          </a>
          <a href="https://discord.gg/UUHqbaR7Wg" target="_blank" rel="noopener noreferrer">
            <FaDiscord className="text-2xl hover:text-gray-400 transition-colors duration-300" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
